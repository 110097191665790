<template>
    <div v-loading.fullscreen.lock="loading">
        <entertain-use-search-bar-component
            add_auth="新增招待物资领用"
            :AllStaff="AllStaff"
            @search="get_entertain_use_index"
            @addTeam="addTeam"
        ></entertain-use-search-bar-component>
        <common-table-component
            details_auth="招待物资领用详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_entertain_use"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_entertain_use_index"
        ></common-page-component>
        <entertain-use-edit-component
            edit_auth="修改招待物资领用"
            del_auth="删除招待物资领用"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :entertain_use_details_data="fentertain_use_details_data"
            :AllStaff="AllStaff"
            :enterprise_department="enterprise_department"
            @exitEntertainUseDialog="dialogExit"
            @search="get_entertain_use_index"
            @show_edit="show_edit"
            @details_row="details_entertain_use"
        ></entertain-use-edit-component>
    </div>
</template>

<script>
import {
    entertain_use_index_request,
    entertain_use_details_request,
} from "@/network/finance/EntertainUse.js";

import { staff_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'
import { enterprise_department_request} from '@/network/enterprise.js'

import EntertainUseSearchBarComponent from "@/components/finance/EntertainUse/EntertainUseSearchBarComponent";
import CommonTableComponent from "@/components/common/CommonTableComponent";
import CommonPageComponent from "@/components/common/CommonPageComponent";
import EntertainUseEditComponent from "@/components/finance/EntertainUse/EntertainUseEditComponent";

export default {
    name: "EntertainUseView",
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: "use_date",
                    label: "领用日期",
                    minWidth: "100px",
                },
                {
                    prop: "name",
                    label: "单号",
                    minWidth: "120px",
                },
                {
                    prop: 'money',
                    label: '金额',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['money']))
                    }
                },
                {
                    prop: "staff_name",
                    label: "领用人",
                    minWidth: "100px",
                },
                {
                    prop: "department_name",
                    label: "所属部门",
                    minWidth: "150px",
                },
                {
                    prop: "enterprise_name",
                    label: "所属企业",
                    minWidth: "260px",
                }
            ],
            fentertain_use_details_data: {},
            AllStaff: [],
            enterprise_department: []
        }
    },
    computed: {},
    methods: {
        // 添加信息（弹框）
        addTeam() {
            this.edit_type = 1;
            this.fdialogFormVisible = true;
        },
        // 修改（弹框）
        show_edit() {
            this.edit_type = 2;
        },
        get_entertain_use_index(param = {}) {
            this.loading = true;
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id;
            this.cond.page = param.page ?? this.cond.page;
            if (param.limit) {
                this.cond.limit = param.limit;
                this.cond.page = 1;
            }
            entertain_use_index_request(this.cond)
                .then((s) => {
                    this.loading = false;
                    if (s.status === 0) {
                        this.ftable_data = s.result.data;
                        this.ftotal = s.result.total;
                    } else {
                        this.$message.error(s.msg);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    this.$message.error(err);
                });
        },
        details_entertain_use(id) {
            this.fid = id;
            this.fdialogFormVisible = true;
            entertain_use_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fentertain_use_details_data = s.result;
                    } else {
                        this.$message.error(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        dialogExit() {
            this.fdialogFormVisible = false;
            this.fid = 0;
            this.edit_type = 0;
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_entertain_use_index()
        this.get_all_staff()
        this.get_enterprise_department()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        EntertainUseSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        EntertainUseEditComponent,
    },
    watch: {},
};
</script>

<style lang="less"></style>
