<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeEntertainUseDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
            </template>
            <div>
                <el-form
                    :model="EntertainUse"
                    :rules="rules"
                    ref="EntertainUseEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="领用部门" prop="department_id">
                                    <el-cascader
                                        v-model="EntertainUse.department_id"
                                        :options="enterprise_department"
                                        :props="{ expandTrigger: 'hover' }"
                                        clearable
                                        style="width: 100%"
                                    >
                                    </el-cascader>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item  label="领用人" prop="staff_id">
                                    <el-select
                                        v-model="EntertainUse.staff_id"
                                        filterable
                                        clearable
                                        placeholder="选择员工"
                                        class="staff_idc"
                                    >
                                        <el-option
                                            v-for="item in AllStaff"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="物资清单" prop="content">
                            <el-input type="textarea" :rows="5" v-model="EntertainUse.content"></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="领用日期" prop="use_date">
                                    <el-date-picker
                                        v-model="EntertainUse.use_date"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="物资总金额" prop="money">
                                    <el-input-number
                                        v-model="EntertainUse.money"
                                        :min="0"
                                        :step="1"
                                        :precision="2"
                                        :step-strictly="false"
                                        size="small"
                                        class="input_number"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                    <template slot="label">
                                        <span>领用部门</span>
                                    </template>
                                    <span>{{EntertainUse.enterprise_name ? EntertainUse.enterprise_name + '/' + EntertainUse.department_name : ''}}</span>
                                </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>领用人</span>
                                </template>
                                <span>{{EntertainUse.staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>物资清单</span>
                                </template>
                                <span>{{EntertainUse.content}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>领用日期</span>
                                </template>
                                <span>{{EntertainUse.use_date}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>物资总金额</span>
                                </template>
                                <span>{{EntertainUse.money | fomatFloatMoney}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="EntertainUseDel('EntertainUseEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeEntertainUseDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('EntertainUseEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeEntertainUseDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('EntertainUseEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeEntertainUseDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { entertain_use_add_request,entertain_use_del_request,entertain_use_edit_request } from '@/network/finance/EntertainUse.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name: 'EntertainUseEditComponent',
    data() {
        return {
            login_loading: false,
            EntertainUse: {
                department_id: '',
                staff_id: '',
                content: '',
                money: '',
                use_date: ''
            },
            rules: {
                department_id: [
                    {
                        required: true,
                        message: '缺少部门',
                        trigger: 'blur',
                    }
                ],
                staff_id: [
                    {
                        required: true,
                        message: '缺少员工',
                        trigger: 'blur',
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '缺少物资清单',
                        trigger: 'blur',
                    }
                ],
                money: [
                    {
                        required: true,
                        message: '缺少物资总金额',
                        trigger: 'blur',
                    }
                ],
                use_date: [
                    {
                        required: true,
                        message: '缺少领用日期',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.EntertainUse.name ? '领用单：' + this.EntertainUse.name : '领用单详情'
            } else if (this.show_type === 1) {
                return '新增招待物资领用'
            } else {
                return '修改招待物资领用'
            }
        }
    },
    methods: {
        closeEntertainUseDialog() {
            this.resetForm()
            this.$emit('exitEntertainUseDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.EntertainUseInsert(formName)
            })
        },
        EntertainUseInsert(formName) {
            this.login_loading = true
            let d = this.EntertainUse
            d.department_id = d.department_id[d.department_id.length - 1]
            entertain_use_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitEntertainUseDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.EntertainUse = {
                department_id: '',
                staff_id: '',
                content: '',
                money: '',
                use_date: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.EntertainUseEdit(formName)
            })
        },
        EntertainUseEdit(formName) {
            this.login_loading = true
            this.EntertainUse.id = this.id
            let d = this.EntertainUse
            d.department_id = d.department_id[d.department_id.length - 1]
            entertain_use_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitEntertainUseDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.EntertainUse.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        EntertainUseDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    entertain_use_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeEntertainUseDialog())
                })
                .catch((err) => {})
        },
    },
    filters: {
        fomatFloatMoney(data) {
            return data ? thousandBitSeparator(fomatFloat(data)) + '元' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        AllStaff: {
            type: Array,
            default() {
                return []
            },
        },
        enterprise_department: {
            type: Array,
            default() {
                return []
            },
        },
        entertain_use_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        entertain_use_details_data(newVal) {
            this.EntertainUse = newVal
        },
    },
}
</script>

<style lang='less'>
.staff_idc,.input_number {
    width: 100%;
}
.el-date-editor.el-input {
    width: 100%;
}
</style>