import request from '../request'

//招待物资领用列表
export const entertain_use_index_request = p => {
    return request({
        method:'GET',
        url:'entertain_use/index',
        params: p
    })
}

//添加招待物资领用
export const entertain_use_add_request = d => {
    return request({
        method:'POST',
        url:'entertain_use/add',
        data: d
    })
}

//删除招待物资领用
export const entertain_use_del_request = id => {
    return request({
        method:'DELETE',
        url:'entertain_use/del',
        data: {
            id: id
        }
    })
}

//修改招待物资领用
export const entertain_use_edit_request = d => {
    return request({
        method:'PUT',
        url:'entertain_use/edit',
        data: d
    })
}

//获取招待物资领用详情
export const entertain_use_details_request = id => {
    return request({
        method:'GET',
        url:'entertain_use/details',
        params: {
            id
        }
    })
}